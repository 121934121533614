@import url("https://rsms.me/inter/inter.css");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rangeslider {
  margin: 8px 0 8px 0px;
  width: 125px;
  position: relative;
  background: #dedede;
  -ms-touch-action: none;
  touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4); */
}
.rangeslider .rangeslider__handle {
  background: #fff;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.2);
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 2px;
  border-radius: 2px;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #0094dd;
  border-radius: 10px;
  top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
